import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const updateProduct = createAsyncThunk('product/updateProduct', 
                                async (product, {getState, rejectWithValue}) => {
        try {
            const { auth } = getState();
            const { userInfo } = auth;
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                }
            }
            const { data } = await axios.put(BASE_URL+'/api/products/update', {
                id: product.id,
                name: product.name,
                price: product.price,
                image: product.image,
                category: product.category,
                brand: product.brand,
                count_in_stock: product.count_in_stock,
                description: product.description
            }, config);
            return data.product;
        } catch(err){
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            return rejectWithValue(JSON.stringify(message)); 
        }

    }
);

const initialState = {
    status: null,
    error: "",
    product: {},
}

const updateProductSlice = createSlice({
    name: 'updateProduct',
    initialState,
    reducers: {
        resetUpdateProduct(state, action) {
            return {
                ...state,
                status: null,
                error: '',
                product: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateProduct.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(updateProduct.fulfilled, (state, action) => {
            return {
                ...state,
                product: action.payload,
                status: "success"
            };
        });
        builder.addCase(updateProduct.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export const { resetUpdateProduct } = updateProductSlice.actions;
export default updateProductSlice.reducer;