import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const deleteUser = createAsyncThunk("users/deleteUser", 
                            async (userId, {getState, rejectWithValue}) => {       
    try {
        const { auth } = getState();
        const { userInfo } = auth;
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const URL = BASE_URL;
        const { data } = await axios.delete(`${URL}/api/users/delete/${userId}`, config);
        return data.message;

    } catch(err){
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        //dispatch({type: detailsOrder.rejected, payload: message});
        return rejectWithValue(JSON.stringify(message)); 
    }
 });

const initialState = {
    error: "",
    status: null,
    user: {}
};

const deleteUserSlice = createSlice({
    name: 'deleteUser',
    initialState,
    reducers: {
        resetDeleteUser (state, action) {
            return {
                ...state,
                status: null,
                error: '',
                user: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteUser.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(deleteUser.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    user: action.payload,
                    status: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(deleteUser.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export const { resetDeleteUser } = deleteUserSlice.actions;
export default deleteUserSlice.reducer;