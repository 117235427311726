import React from 'react'
import { Link } from 'react-router-dom';
import Rating from './Rating';
import { IMAGE_URL } from './utils';

export default function Product(props) {
    const { product } = props;
    
    
  return (
    <div>
        <div key={product.id} className="card">
            <Link to={`/product/${product.id}`}>
                <img className="medium" src={IMAGE_URL+product.image} alt={product.name} />
            </Link>
            <div className="card-body">
                <Link to={`/product/${product.id}`}>
                    <h2>{product.name}</h2>
                </Link>
                <Rating rating={product.rating} numReviews={product.num_reviews} >

                </Rating>
                <div className="row">
                    <div className="price">
                        ${product.price}
                    </div>
                    <div>
                        <Link to={`/seller/${product.seller_id}`}>
                            {product.seller_name}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
