import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const deleteOrder = createAsyncThunk('orders/deleteOrder', 
                                    async (id, {rejectWithValue, getState}) => {
    let { auth } = getState();
    let { userInfo } = auth;
    try {
    
    let config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        }
    }
    const URL = BASE_URL;
    const { data } = await axios.delete(`${URL}/api/orders/delete/${id}`, config);
    return data.product;
    } catch(err){
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            return rejectWithValue(JSON.stringify(message)); 
        }
});

const initialState = {
    status: null,
    error: "",
    order: {},
}

const deleteOrderSlice = createSlice({
    name: 'deleteOrder',
    initialState,
    reducers: {
        resetDeleteOrder (state, action) {
            return {
                ...state,
                status: null,
                error: '',
                order: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteOrder.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(deleteOrder.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    order: action.payload,
                    status: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(deleteOrder.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export const { resetDeleteOrder } = deleteOrderSlice.actions;
export default deleteOrderSlice.reducer;