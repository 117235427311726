import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const payOrder = createAsyncThunk("orders/payOrder", 
    async (values, {getState, rejectWithValue, dispatch}) => {       

    try {
        const { auth } = getState();
        const { userInfo } = auth;
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const URL = BASE_URL;
        const { data } = await axios.put(`${URL}/api/orders/pay/${values.id}`, 
        {
            status: values.data.status,
            update_time: values.data.update_time,
            email_address: values.data.email_address,
        }
        ,
            config
        );
        return data.order;

    } catch(err){
        const message = err.response && err.response.data.message 
        ? err.response.data.message
        : err.message;
        //dispatch({type: detailsOrder.rejected, payload: message});
        return rejectWithValue(JSON.stringify(message)); 
    }
});

const initialState = {
    status: null,
    error: "",
    order: {}
};
const payOrderSlice = createSlice({
    name: "payOrderData",
    initialState,
    reducers: {
        resetPayOrder(state, action) {
            return {
                ...state,
                status: null,
                error: '',
                order: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(payOrder.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(payOrder.fulfilled, (state, action) => {
            return {
                ...state,
                order: action.payload,
                status: "success"
            };
        });
        builder.addCase(payOrder.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export const { resetPayOrder } = payOrderSlice.actions;
export default payOrderSlice.reducer;
