import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../components/utils';

export const createReview = createAsyncThunk('products/createReview', async (values, { getState,rejectWithValue}) => {
    try {
        const { auth } = getState();
        const { userInfo } = auth;
        
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const response = await axios.post(BASE_URL+'/api/products/product/review', 
                {
                    id: values.id,
                    comment: values.comment,
                    rating: values.rating,
                    name: values.name,
                }, config);
        return response.data;
    } catch (err) {
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        return rejectWithValue(JSON.stringify(message)); 
    }
    
});

export const getReviews = createAsyncThunk('products/getReviews', async (id, { getState,rejectWithValue}) => {
    try {
        const URL = BASE_URL;
        const response = await axios.get(`${URL}/api/products/product/reviews/${id}`);
        return response.data;
    } catch (err) {
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        return rejectWithValue(JSON.stringify(message)); 
    }
    
});



const initialState = {
    review: {},
    reviews: [],
    createStatus: null,
    createError: "",
    getError: ""
}

const ReviewSlice = createSlice({
    name: "reviewData",
    initialState,
    reducers: {
        resetCreateReview(state, action) {
            return {
                ...state,
                createStatus: null,
                createError: "",
                review: {}
        }
      }
    },
    extraReducers: (builder) => {
        builder.addCase(createReview.pending, (state, action) => {
            return { ...state, createStatus: "pending" };
        });
        builder.addCase(createReview.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    review: action.payload,
                    createStatus: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(createReview.rejected, (state, action) => {
            return {
                ...state,
                createStatus: "rejected",
                createError: action.payload
            }
        });
        builder.addCase(getReviews.pending, (state, action) => {
            return { ...state, getStatus: "pending" };
        });
        builder.addCase(getReviews.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    reviews: action.payload,
                    getStatus: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(getReviews.rejected, (state, action) => {
            return {
                ...state,
                getStatus: "rejected",
                getError: action.payload
            }
        });
    }
});

export const { resetCreateReview } = ReviewSlice.actions;
export default ReviewSlice.reducer;