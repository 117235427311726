import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { detailsUser } from '../features/ProfileSlice';
import { resetUpdateUser, updateUserData } from '../features/UpdateUserSlice';

function UserEditScreen() {
    const { id } = useParams();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    //const userDetails = useSelector(state => state.userProfile);
    //const { status, error, user: userData } = userDetails;
    const userUpdate = useSelector(state => state.userUpdate);
    const {status: updateStatus, error: updateError, targetUser } = userUpdate;
    const[name, setName] = useState(targetUser.name);
    const[email, setEmail] = useState(targetUser.email);
    const[is_admin, setIsAdmin] = useState(targetUser.is_admin);
    const[is_seller, setIsSeller] = useState(targetUser.is_seller);

    const submitHandler = (e) => {
        // Implement onSubmit here
        e.preventDefault();
        dispatch(updateUserData({id: id, is_admin, is_seller}));
    }
    useEffect(() => {
        if(updateStatus === 'success') {
            dispatch(resetUpdateUser());
            navigate('/users');
        }
      
    },[dispatch, updateStatus, navigate]);
    return (
        <div>
                  {  updateStatus === "pending" && <LoadingBox>Loading...</LoadingBox> }
                  {  updateStatus === "rejected" && <MessageBox variant="danger">{updateError}</MessageBox> }
            <form className="form" onSubmit={submitHandler} >
                <div>
                    <h1>Edit User</h1>
                </div>
                <>
                    <div>
                        <label htmlFor='name'>Name</label>
                        <input id='name' type='text' placeholder='Enter Fullname' value={name} 
                            onChange={(e) => setName(e.target.value)}
                        ></input>
                    </div>
                    <div>
                        <label htmlFor='email'>Email</label>
                        <input id='email' type='email' placeholder='Enter Email' value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                        ></input>
                    </div>
                    <div className='align-left'>
                        <label htmlFor='isAdmin'>Is Admin:</label>
                        <input id='isAdmin' type='checkbox' checked={is_admin} 
                            onChange={(e) => setIsAdmin(e.target.checked)}
                        ></input>
                    </div>
                    <div className='align-left'>
                        <label htmlFor='isSeller'>Is Seller:</label>
                        <input id='isSeller' type='checkbox' checked={is_seller} 
                            onChange={(e) => setIsSeller(e.target.checked)}
                        ></input>
                    </div>
                    <div>
                        <label></label>
                        <button className='primary' type='submit'>
                            Update User
                        </button>
                    </div>
                </>
            </form>
        </div>
    )
}

export default UserEditScreen;
