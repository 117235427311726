import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const deleteProduct = createAsyncThunk('product/deleteProduct', 
                                    async (id, {rejectWithValue, getState}) => {
    let { auth } = getState();
    let { userInfo } = auth;
    try {
    
    let config = {
        headers: {
            Authorization: `Bearer ${userInfo.token}`,
        }
    }
    const URL = BASE_URL;
    const { data } = await axios.delete(`${URL}/api/products/delete/${id}`,config);
    return data.product;
    } catch(err){
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            //dispatch({type: detailsOrder.rejected, payload: message});
            return rejectWithValue(JSON.stringify(message)); 
        }
});

const initialState = {
    status: null,
    error: "",
    product: {},
}

const deleteProductSlice = createSlice({
    name: 'deleteProduct',
    initialState,
    reducers: {
        resetDeleteProduct (state, action) {
            return {
                ...state,
                status: null,
                error: '',
                product: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteProduct.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(deleteProduct.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    product: action.payload,
                    status: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(deleteProduct.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export const { resetDeleteProduct } = deleteProductSlice.actions;
export default deleteProductSlice.reducer;