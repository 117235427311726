import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../components/utils';

export const listProductCategories = createAsyncThunk('products/listProductCategory', async (values, {rejectWithValue}) => {
    try {
        const response = await axios.get(BASE_URL + '/api/products/categories');
        return response.data;
    } catch (err) {
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        return rejectWithValue(JSON.stringify(message)); 
    }
    
});

const initialState = {
    categories: [],
    status: null,
    error: ""
}


const productCategorySlice = createSlice({
    name: "productCategories",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(listProductCategories.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(listProductCategories.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    categories: action.payload,
                    status: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(listProductCategories.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export default productCategorySlice.reducer