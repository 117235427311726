import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const updateSeller = createAsyncThunk('/seller/updateProfile', async (
    seller, {getState, rejectWithValue, dispatch}) => {

        try {
            const { auth } = getState();
            const { userInfo } = auth;
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                }
            }
            const { data } = await axios.put(BASE_URL+'/api/seller/update', {
                id: seller.id,
                name: seller.name,
                logo: seller.logo,
                description: seller.description,
            }, config);
            return data.seller;
        } catch(err){
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            return rejectWithValue(JSON.stringify(message));
        }
        
    }
);

const initialState = {
    status: null,
    error: '',
    updateSeller: {}
};

const sellerUpdateSlice = createSlice({
    name: "updateSellerProfile",
    initialState,
    reducers: {
        resetUpdateSeller(state, action) {
            return {
                ...state,
                status: null,
                error: '',
                seller: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateSeller.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(updateSeller.fulfilled, (state, action) => {
            return {
                ...state,
                updateSeller: action.payload,
                status: "success"
            };
        });
    builder.addCase(updateSeller.rejected, (state, action) => {
        return {
            ...state,
            status: "rejected",
            error: action.payload
        }
    });
}
});

export const { resetSellerUpdate } = sellerUpdateSlice.actions;
export default sellerUpdateSlice.reducer;
