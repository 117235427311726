import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { createProduct, resetCreateProduct } from '../features/CreateProductSlice';
import { deleteProduct, resetDeleteProduct } from '../features/DeleteProductSlice';
import { getSeller } from '../features/SellerSlice';
import { fetchProductList } from '../features/ProductListSlice';

export default function ProductListScreen() {
    //const sellerMode = props.match.path.indexOf('/seller') >= 0;
    const auth = useSelector(state => state.auth);
    const { userInfo } = auth;
    const sellerMode = window.location.href.indexOf('/seller') >= 0;
    const adminMode = window.location.href.indexOf('/admin') >= 0;
    const mode = adminMode ? 'admin' : 'seller'; 
    const productList = useSelector(state => state.productList);
    const { products, error, status } = productList;
    const sellerData = useSelector(state => state.sellerData);
    const { seller } = sellerData;
    const productCreate = useSelector(state => state.productCreate);
    const {
        product: createdProduct, 
        error: createdError, 
        status: statusCreate,
    } = productCreate;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const productDelete = useSelector(state => state.productDelete);
    const {status: deleteStatus, error: deleteError} = productDelete;

    const deleteHandler = (product) => {
        if (window.confirm('Are you sure you want to delete the product?')) {
            dispatch(deleteProduct(product.id));
        }
        
    }
    
    
    const createHandler = (e) => {
        e.preventDefault();
        if(sellerMode){
            dispatch(createProduct(seller.id));
        }
        if(adminMode) {
            dispatch(createProduct(userInfo.id));
        }
        
    }

    useEffect(() => {
        if(statusCreate === "success") {
            dispatch(resetCreateProduct());
            navigate(`/product/${mode}/${createdProduct.id}/edit`); 
        }  
        if(deleteStatus === 'success') {
            dispatch(resetDeleteProduct());
        } 
        if(sellerMode) { dispatch(getSeller({id:userInfo.id, by_seller: true}));}
        if(adminMode) {
            dispatch(fetchProductList({is_admin: true}));
        } else {
            dispatch(fetchProductList({id: seller.id, is_admin: false}));
        }
    }, [dispatch, createdProduct, navigate, statusCreate, deleteStatus, sellerMode, userInfo.id, seller.id, adminMode]);
    
  return (
    <div>
        <div className='row'>
            <h1>Products</h1>
            <button type='button' className='primary' onClick={createHandler} >
                Create Product
            </button>
        </div>
        {deleteStatus === "pending" && (<LoadingBox>Deleting...</LoadingBox>)}
        {deleteStatus === "rejected" && (<MessageBox variant="danger">{deleteError}</MessageBox>)}
        {deleteStatus === "success" && (<MessageBox variant='normal'>Product Deleted!</MessageBox>)}
        {statusCreate === "pending" && (<LoadingBox>Creating...</LoadingBox>) }
        {statusCreate === "rejected" && (<MessageBox variant="danger">{createdError}</MessageBox>)}
        {status === "pending" && (<LoadingBox>Loading...</LoadingBox> ) }
        { status === "rejected" && (<MessageBox variant="danger">{error}</MessageBox>) }
        
        <table className='table'>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>PRICE</th>
                    <th>CATEGORY</th>
                    <th>BRAND</th>
                    <th>ACTIONS</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product) => (
                    <tr key={product.id}>
                        <td>{product.id}</td>
                        <td>{product.name}</td>
                        <td>{product.price}</td>
                        <td>{product.category}</td>
                        <td>{product.brand}</td>
                        <td>
                            <button type='button' className='small' onClick={() => navigate(`/product/${mode}/${product.id}/edit`)}>
                                Edit
                            </button>
                            <button type='button' className='samll' onClick={() => deleteHandler(product)} >
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
  )
}
