import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../components/utils';

export const fetchProductList = createAsyncThunk("products/fetchProducList", async (value, {getState, rejectWithValue}) => {
    try {
        const { auth } = getState();
        const { userInfo } = auth;
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const response = await axios.post(BASE_URL+'/api/products/getProducts', {id: value.id, is_admin: value.is_admin}, config);
        return response.data;
    } catch(err){
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        return rejectWithValue(JSON.stringify(message)); 
        }
});

const initialState = {
    status: null,
    error: "",
    products: [],
}

const productListSlice = createSlice({
    name: "productList",
    initialState,
    reducers: {
        resetProductDetails (state, action) {
            return {
                ...state,
                status: null,
                error: '',
                product: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProductList.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(fetchProductList.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    products: action.payload,
                    status: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(fetchProductList.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
})

export const { resetProductDetails } = productListSlice.actions;
export default productListSlice.reducer