import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const createProduct = createAsyncThunk('product/createProduct', async (seller, {getState, rejectWithValue}) => {

    try {
        const { auth } = getState();
        const { userInfo } = auth;
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const response = await axios.post(BASE_URL + '/api/products/create', {seller: seller}, config);
        return response.data;
    } catch(err){
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            //dispatch({type: detailsOrder.rejected, payload: message});
            return rejectWithValue(JSON.stringify(message)); 
        }
});

const initialState = {
    status: null,
    error: "",
    product: {},
}

const createProductSlice = createSlice({
    name: 'createProduct',
    initialState,
    reducers: {
        resetCreateProduct (state, action) {
            return {
                ...state,
                status: null,
                error: '',
                product: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createProduct.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(createProduct.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    product: action.payload,
                    status: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(createProduct.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export const { resetCreateProduct } = createProductSlice.actions;
export default createProductSlice.reducer;