import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { fetchProductDetails } from '../features/ProductDetailsSlice';
import { resetUpdateProduct, updateProduct } from '../features/UpdateProductSlice';
import { BASE_URL } from '../components/utils';

function ProductEditScreen() {

    const adminMode = window.location.href.indexOf('/admin') >= 0;
    const mode = adminMode ? 'admin' : 'seller'; 
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { id } = useParams();
    
    const auth = useSelector(state => state.auth);
    const { userInfo } = auth;

    const { status, error, product } = useSelector(state => state.productDetails);

    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [image, setImage] = useState('');
    const [category, setCategory] = useState('');
    const [count_in_stock, setCountInStock] = useState('');
    const [brand, setBrand] = useState('');
    const [description, setDescription] = useState('');

    const productUpdate = useSelector(state => state.productUpdate);
    const {status: updateStatus, error: updateError } = productUpdate;

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState('');

    const uploadFileHandler = async (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        //const reader = new FileReader();
        //reader.readAsDataURL(file);
        let bodyFormData = new FormData();
        bodyFormData.append('image', file);
        setLoadingUpload(true);
        try {
            const config = {
                headers: {
                    'Content-Type':'multipart/form-data',
                    Authorization:`Bearer ${userInfo.token}`,
                }
            }
            console.log(bodyFormData);
            const { data } = await axios.post(BASE_URL+'/api/uploads', bodyFormData, config);
            
            //const data = bodyFormData.move(process.env.PUBLIC_URL + '/images');
            setImage(data.image);
            setLoadingUpload(false);
        } catch (err) {
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            setErrorUpload(message);
            setLoadingUpload(false);
        }
    }
    const submitHandler = (e) => {
        // Implement onSubmit here
        e.preventDefault();
        dispatch(updateProduct({id: id,
                                    name, price, image, category, brand, count_in_stock, description})
                );
    }
    useEffect(() => {
        if(!product || product.id !== id) {
            dispatch(fetchProductDetails(id));
        }
        setData();
        if(updateStatus === 'success') {
            dispatch(resetUpdateProduct());
            navigate(`/productlist/${mode}`);
        }
    },[dispatch, id, navigate, product.id, updateStatus]);
   
    const setData = () => {
        setName(product.name);
        setPrice(product.price);
        setCategory(product.category);
        setBrand(product.brand);
        setCountInStock(product.count_in_stock);
        setImage(product.image);
        setDescription(product.description);
    }
    return (
        <div>
        {  updateStatus === "pending" && <LoadingBox>Loading...</LoadingBox> }
        {  updateStatus === "rejected" && <MessageBox variant="danger">{updateError}</MessageBox> }
        {
        status === 'pending' ? (<LoadingBox>Loading product data...</LoadingBox>) : 
        status === "rejected" ? (<MessageBox variant="danger">{error}</MessageBox>) : 
        (
            <form className="form" onSubmit={submitHandler} encType="multipart/form-data">
                <div>
                    <h1>Edit Product</h1>
                </div>
                {status === 'pending' ? (<LoadingBox>Loading...</LoadingBox>) : 
                status === 'rejected' ? (<MessageBox varian="danger">{error}</MessageBox>) :
                <>
                    <div>
                        <label htmlFor='name'>Name</label>
                        <input id='name' type='text' placeholder='Enter Name' value={name} 
                            onChange={(e) => setName(e.target.value)}
                        ></input>
                    </div>
                    <div>
                        <label htmlFor='price'>Price</label>
                        <input id='price' type='text' placeholder='Enter Price' value={price} 
                            onChange={(e) => setPrice(e.target.value)}
                        ></input>
                    </div>
                    <div>
                        <label htmlFor='image'>Image</label>
                        <input id='image' type='text' placeholder='Enter Image' value={image} 
                            onChange={(e) => setImage(e.target.value)}
                        ></input>
                    </div>
                    <div>
                        <label htmlFor='imageFile'>Image File</label>
                        <input type='file' id='imageFile' label='Choose Image'
                            onChange={uploadFileHandler}
                        ></input>
                        {loadingUpload && (<LoadingBox>Uploading...</LoadingBox>)}
                        {errorUpload && <MessageBox variant="danger">{errorUpload}</MessageBox>}
                    </div>
                    <div>
                        <label htmlFor='category'>Category</label>
                        <input id='category' type='text' placeholder='Enter Category' value={category} 
                            onChange={(e) => setCategory(e.target.value)}
                        ></input>
                    </div>
                    <div>
                        <label htmlFor='brand'>Brand</label>
                        <input id='brand' type='text' placeholder='Enter Brand' value={brand} 
                            onChange={(e) => setBrand(e.target.value)}
                        ></input>
                    </div>
                    <div>
                        <label htmlFor='countInStock'>Count In Stock</label>
                        <input id='countInStock' type='number' placeholder='Enter Amount' value={count_in_stock} 
                            onChange={(e) => setCountInStock(e.target.value)}
                        ></input>
                    </div>
                    <div>
                        <label htmlFor='description'>Description</label>
                        <textarea id='description' type='text' placeholder='Enter description' value={description} 
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                    </div>
                    <div>
                        <label></label>
                        <button className='primary' type='submit'>
                            Update Product
                        </button>
                    </div>
                </>
                }
            </form>
        )}
        </div>
    )
}

export default ProductEditScreen
