import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { resetSellerUpdate, updateSeller } from '../features/UpdateSellerSlice';
import { getSeller } from '../features/SellerSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../components/utils';

function SellerProfileScreen() {

    const auth = useSelector((state) => state.auth);
    const { userInfo } = auth;
    const { status, error, seller } = useSelector((state) => state.sellerData);
     
    const { status: updateSellerStatus, error: updateSellerError } = useSelector(state => state.updateSellerProfile);

    const [name, setName] = useState('');
    const [logo, setLogo] = useState('');
    const [description, setDescription] = useState('');
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loadingUpload, setLoadingUpload] = useState(false);
    const [errorUpload, setErrorUpload] = useState('');

    const uploadFileHandler = async (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        //const reader = new FileReader();
        //reader.readAsDataURL(file);
        let bodyFormData = new FormData();
        bodyFormData.append('image', file);
        setLoadingUpload(true);
        try {
            const config = {
                headers: {
                    'Content-Type':'multipart/form-data',
                    Authorization:`Bearer ${userInfo.token}`,
                }
            }
            const { data } = await axios.post(BASE_URL+'/api/uploads', bodyFormData, config);
            
            //const data = bodyFormData.move(process.env.PUBLIC_URL + '/images');
            setLogo(data.image);
            setLoadingUpload(false);
        } catch (err) {
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            setErrorUpload(message);
            setLoadingUpload(false);
        }
    }
    
    useEffect(() => {
        if(!userInfo) {
            navigate('/login');
        }
        if(!seller.id) {
            dispatch(getSeller({id: userInfo.id, by_seller: true}));
        } else {
            setName(seller.name);
            setLogo(seller.logo);  
            setDescription(seller.description);
        }
        
    }, [navigate, userInfo, dispatch, seller.id]);

    const submitHandler = (e) => {
        e.preventDefault();
        if(name === '' || logo === '' || description === '') {
            alert("Please fill in the seller data!");
        } else {
            dispatch(updateSeller({id: seller.id, name, logo, description}));
            resetSellerUpdate();
        }
    }
    return (
        <div>
            <form className='form' onSubmit={submitHandler} encType="multipart/form-data">
                <div>
                    <h1>Seller Profile</h1>
                </div>
                {
                    status === "pending" ? (
                        <LoadingBox>Loading...</LoadingBox>
                    ) : status === "rejected" ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                    ) : (
                        <>
                            {updateSellerStatus === "pending" && (<LoadingBox>Updating...</LoadingBox>)}
                            {updateSellerStatus === "rejected" && (<MessageBox variant="danger">{updateSellerError}</MessageBox>)}
                            {updateSellerStatus === "success" && (
                                <MessageBox variant="success">Profile Updated Successfully</MessageBox>
                            )}
                            <div>
                                <label htmlFor='name'>Name</label>
                                <input id='name' type="text" placeholder='Enter name' onChange={(e) => setName(e.target.value)}
                                    defaultValue={seller.name} ></input>
                            </div>
                            <div>
                            <label htmlFor='logo'>Logo</label>
                            <input id='logo' type='text' placeholder='Enter Image' defaultValue={logo} 
                            onChange={(e) => setLogo(e.target.value)}
                                ></input>
                            </div>
                            <div>
                                <label htmlFor='imageFile'>Image File</label>
                                <input type='file' id='imageFile' label='Choose Image'
                                    onChange={uploadFileHandler}
                                ></input>
                                {loadingUpload && (<LoadingBox>Uploading...</LoadingBox>)}
                                {errorUpload && <MessageBox variant="danger">{errorUpload}</MessageBox>}
                            </div>
                            <div>
                                <label htmlFor='description'>Description</label>
                                <input id='description' type="text" placeholder='Enter description'
                                    defaultValue={seller.description} onChange={(e) => setDescription(e.target.value)} ></input>
                            </div>
        
                            <div>
                                <label/>
                                <button className='primary' type='submit'>Update</button>
                            </div>
                        </>
                    )
                }
            </form>
        </div>
    )
}

export default SellerProfileScreen
