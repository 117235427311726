import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const deliverOrder = createAsyncThunk('orders/deliverOrder', 
                                    async (id, {rejectWithValue, getState}) => {
    try {
    
        const { auth } = getState();
        const { userInfo } = auth;
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const URL = BASE_URL;
        const { data } = await axios.put(`${URL}/api/orders/deliver/${id}`, {},config);
        return data.order;
    } catch(err){
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            //dispatch({type: detailsOrder.rejected, payload: message});
            return rejectWithValue(JSON.stringify(message)); 
        }
});

const initialState = {
    status: null,
    error: "",
    order: {},
}

const deliverOrderSlice = createSlice({
    name: 'orderDeliver',
    initialState,
    reducers: {
        resetDeliverOrder (state, action) {
            return {
                ...state,
                status: null,
                error: '',
                order: {}
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deliverOrder.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(deliverOrder.fulfilled, (state, action) => {
            return {
                ...state,
                order: action.payload,
                status: "success"
            };
        });
        builder.addCase(deliverOrder.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export const { resetDeliverOrder } = deliverOrderSlice.actions;
export default deliverOrderSlice.reducer;