import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const getOrderItems = createAsyncThunk("order/getOrderItems", 
                                async (orderId, {getState, rejectWithValue, dispatch}) => {
    try {
        const { auth } = getState();
        const { userInfo } = auth;
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const URL = BASE_URL;
        const { data } = await axios.get(`${URL}/api/orders/orderItems/${orderId}`, config);
        return data;
    } catch (err) {
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        //dispatch({type: detailsOrder.rejected, payload: message});
        return rejectWithValue(JSON.stringify(message));
    }
});
const initialState = {
    orderItemsData: "",
    orderItemsError: "",
    orderItemsStatus: "",
}
const orderItemsSlice = createSlice({
    name: "orderItems",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getOrderItems.pending, (state, action) => {
            return { ...state, orderItemsStatus: "pending" };
        });

        builder.addCase(getOrderItems.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    orderItemsData: action.payload,
                    orderItemsStatus: "success",
                };
            } else {
                return state;
            }
        });

        builder.addCase(getOrderItems.rejected, (state, action) => {
            return {
                ...state,
                orderItemsStatus: "rejected",
                orderItemsError: action.payload
            }
        });
    }
});

export default orderItemsSlice.reducer;