import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../components/utils";

export const updateUserData = createAsyncThunk('users/updateUser', 
                                async (user, {getState, rejectWithValue}) => {
        try {
            const { auth } = getState();
            const { userInfo } = auth;
            const config = {
                headers: {
                    Authorization: `Bearer ${userInfo.token}`,
                }
            }
            const URL = BASE_URL;
            const { data } = await axios.put(`${URL}/api/users/user/update/${user.id}`, {
                is_admin: user.is_admin,
                is_seller: user.is_seller,
            }, config);
            return data.user;
        } catch(err){
            const message = err.response && err.response.data.message 
                            ? err.response.data.message
                            : err.message;
            return rejectWithValue(JSON.stringify(message)); 
        }

    }
);

const initialState = {
    status: null,
    error: "",
    user: {},
    targetUser: {},
}

const updateUserSlice = createSlice({
    name: 'userUpdate',
    initialState,
    reducers: {
        resetUpdateUser(state, action) {
            return {
                ...state,
                status: null,
                error: '',
                user: {}
            }
        },
        setTargetUser(state, action) {
            return {
                 ...state,
                targetUser: action.payload,
            }
        }
        },
        extraReducers: (builder) => {
            builder.addCase(updateUserData.pending, (state, action) => {
                return { ...state, status: "pending" };
            });
            builder.addCase(updateUserData.fulfilled, (state, action) => {
                if(action.payload) {
                    return {
                        ...state,
                        seller: action.payload,
                        status: "success"
                    };
                } else {
                    return state;
                }
            });
            builder.addCase(updateUserData.rejected, (state, action) => {
                return {
                    ...state,
                    status: "rejected",
                    error: action.payload
                }
            });
        
    },
});


export const { resetUpdateUser, setTargetUser } = updateUserSlice.actions;
export default updateUserSlice.reducer;