import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { detailsUser } from '../features/ProfileSlice';
import { updateProfile } from '../features/UpdateProfileSlice';

function ProfileScreen() {

    const auth = useSelector((state) => state.auth);
    const { userInfo } = auth;

    const userProfile = useSelector((state) => state.userProfile);
    const { status, error, user } = userProfile;
    const userUpdateProfile = useSelector(state => state.userUpdateProfile);
    const { status: updateProfileStatus, error: updateProfileError } = userUpdateProfile;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(!user.name || user.id !== userInfo.id) {
            dispatch(detailsUser(userInfo.id));
        } else {
            setName(user.name);
            setEmail(user.email);  
        }
        
    }, [dispatch, user, userInfo.id]);

    const submitHandler = (e) => {
        e.preventDefault();
        if(password !== confirmPassword) {
            alert("Password does not match confirm password!");
        } else {
            dispatch(updateProfile({id: user.id, name:name, email: email, password: password, password_confirmation: confirmPassword }));
        }

    }
    return (
        <div>
            <form className='form' onSubmit={submitHandler}>
                <div>
                    <h1>User Profile</h1>
                </div>
                {
                    status === "pending" ? (
                        <LoadingBox>Loading...</LoadingBox>
                    ) : status === "rejected" ? (
                        <MessageBox variant="danger">{error}</MessageBox>
                    ) : (
                        <>
                            {updateProfileStatus === "pending" && (<LoadingBox>Updating...</LoadingBox>)}
                            {updateProfileStatus === "rejected" && (<MessageBox variant="danger">{updateProfileError}</MessageBox>)}
                            {updateProfileStatus === "fulfilled" && (
                                <MessageBox variant="success">Profile Updated Successfully</MessageBox>
                            )}
                            <div>
                                <label htmlFor='name'>Name</label>
                                <input id='name' type="text" placeholder='Enter name' onChange={(e) => setName(e.target.value)}
                                    defaultValue={user.name} ></input>
                            </div>
                            <div>
                                <label htmlFor='email'>Email</label>
                                <input id='email' type="email" placeholder='Enter email'
                                    defaultValue={user.email} onChange={(e) => setEmail(e.target.value)} ></input>
                            </div>
                            <div>
                                <label htmlFor='password'>Password</label>
                                <input id='password' type="password" placeholder='Enter password'
                                   onChange={(e) => setPassword(e.target.value)} ></input>
                            </div>
                            <div>
                                <label htmlFor='confirmPassord'>Confirm Password</label>
                                <input id='confirmPassowrd' type="password" placeholder='Enter confirm password'
                                    onChange={(e) => setConfirmPassword(e.target.value)} ></input>
                            </div>
        
                            <div>
                                <label/>
                                <button className='primary' type='submit'>Update</button>
                            </div>
                        </>
                    )
                }
            </form>
        </div>
    )
}

export default ProfileScreen
