import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../components/utils';

export const getSeller = createAsyncThunk("sellers/fetchSeller", 
                            async (value, { rejectWithValue }) => {       
    try {      
        var id = value.id; 
        const URL = BASE_URL; 
        if(value.by_seller) {
            const response = await axios.get(`${URL}/api/seller/data/${id}`);
            return response.data;
        } else {
            const response = await axios.get(`${URL}/api/seller/${id}`);
            return response.data;
        }

    } catch(err){
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        return rejectWithValue(JSON.stringify(message)); 
    }
});

const initialState = {
    error: "",
    status: "pending",
    seller: {},
};

const sellerSlice = createSlice({
    name: "sellerData",
    initialState,
    reducers: {
      
    },
    extraReducers: (builder) => {
        builder.addCase(getSeller.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(getSeller.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    seller: action.payload,
                    status: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(getSeller.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export default sellerSlice.reducer;

