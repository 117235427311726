import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { emptyCart } from './CartSlice';
import { BASE_URL } from '../components/utils';

export const createOrder = createAsyncThunk("order/createOrder", 
                            async (values, {getState, rejectWithValue, dispatch }) => {       
       
    
    try {
        const { auth } = getState();
        const { userInfo } = auth;
        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            }
        }
        const { data } = await axios.post(BASE_URL + "/api/orders/create", {
            orderItems: values.cartItems,
            shippingAddress: values.shippingAddress,
            paymentMethod: values.paymentMethod,
            itemsPrice: values.itemsPrice,
            shippingPrice: values.shippingPrice,
            taxPrice: values.taxPrice,
            totalPrice: values.totalPrice,
            seller: values.seller,
            user_id: values.user_id,
            payment_method: values.payment_method,
        }, config
        
        );
        dispatch(emptyCart());
        localStorage.removeItem("cartItems");
        return data.order;

    } catch(err){
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        //dispatch({type: detailsOrder.rejected, payload: message});
        return rejectWithValue(JSON.stringify(message)); 
    }
});

const initialState = {
    error: "",
    status: "",
    order: {}
};

const orderSlice = createSlice({
    name: "orderCreate",
    initialState,
    reducers: {
        resetOrder (state, action) {
            return {
               ...state,
               error: "",
               order: {},
               status: "",
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createOrder.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(createOrder.fulfilled, (state, action) => {
            if(action.payload) {
                return {
                    ...state,
                    order: action.payload,
                    status: "success"
                };
            } else {
                return state;
            }
        });
        builder.addCase(createOrder.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
});

export const { resetOrder } = orderSlice.actions;
export default orderSlice.reducer;

