import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CheckoutSteps from '../components/CheckoutSteps'
import { saveShippingAddress } from '../features/CartSlice';

export default function ShippingAddressScreen() {
    
    const cart = useSelector(state => state.cart);
    const { shippingAddress } = cart;
    const [fullName, setFullName] = useState(shippingAddress.fullName);
    const [address, setAddress] = useState(shippingAddress.address);
    const [city, setCity] = useState(shippingAddress.city);
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);
    const [country, setCountry] = useState(shippingAddress.country);
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const { userInfo } = auth;
    
    
    const dispatch = useDispatch();
    
    const submitHandler = (e) => {
        e.preventDefault();
        //TODO: dispatch save shipping address action
        dispatch(saveShippingAddress({fullName, address, city, postalCode, country}));
        navigate('/payment');
    }

    useEffect(() => {
        if(!userInfo) {
            navigate('/signin');
        }
    }, [navigate, userInfo]);
  return (
    <div>
        <CheckoutSteps step1 step2></CheckoutSteps>
        <form className="form" onSubmit={submitHandler}>
            <div>
                <h1>Shipping Address</h1>
            </div>
            <div>
                <label htmlFor="fullName">Full Name</label>
                <input
                    type="text"
                    id="fullName"
                    placeholder="Enter full name"
                    value={fullName || ''}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                ></input>
            </div>
            <div>
                <label htmlFor="address">Address</label>
                <input
                    type="text"
                    id="address"
                    placeholder="Enter Address"
                    value={address || ''}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                ></input>
            </div>
            <div>
                <label htmlFor="city">City</label>
                <input
                    type="text"
                    id="city"
                    placeholder="Enter City"
                    value={city || ''}
                    onChange={(e) => setCity(e.target.value)}
                    required
                ></input>
            </div>
            <div>
                <label htmlFor="postalCode">Postal Code</label>
                <input
                    type="text"
                    id="postalCode"
                    placeholder="Enter postal code"
                    value={postalCode || ''}
                    onChange={(e) => setPostalCode(e.target.value)}
                    required
                ></input>
            </div>
            <div>
                <label htmlFor="country">Country</label>
                <input
                    type="text"
                    id="country"
                    placeholder="Enter Country"
                    value={country || ''}
                    onChange={(e) => setCountry(e.target.value)}
                    required
                ></input>
            </div>
            <div>
                <button className="primary" type="submit">
                    Continue
                </button>
            </div>
        </form>
    </div>
  );
}