import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../components/utils';

export const fetchProductDetails = createAsyncThunk("products/fetchProductDetails", async (id, {rejectWithValue}) => {
    try {
        const URL = BASE_URL;
    const response = await axios.get(`${URL}/api/products/product/${id}`);
    return response.data;
    } catch(err){
        const message = err.response && err.response.data.message 
                        ? err.response.data.message
                        : err.message;
        //dispatch({type: detailsOrder.rejected, payload: message});
        return rejectWithValue(JSON.stringify(message)); 
        }
});

const initialState = {
    status: null,
    error: "",
    product: {},
}

const productDetailsSlice = createSlice({
    name: "productDetails",
    initialState,
    reducers: {
        resetProductDetails (state, action) {
            return {
                ...state,
                status: null,
                error: '',
                product: null,
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProductDetails.pending, (state, action) => {
            return { ...state, status: "pending" };
        });
        builder.addCase(fetchProductDetails.fulfilled, (state, action) => {
            return {
                ...state,
                product: action.payload,
                status: "success"
            };
        });
        builder.addCase(fetchProductDetails.rejected, (state, action) => {
            return {
                ...state,
                status: "rejected",
                error: action.payload
            }
        });
    }
})

export const { resetProductDetails } = productDetailsSlice.actions;
export default productDetailsSlice.reducer