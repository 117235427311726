export const prices = [
    {
        name: 'any',
        min: 0,
        max: 0,
    },
    {
        name: `$1 to $10`,
        min: 1,
        max: 10,
    },
    {
        name: `$10 to $100`,
        min: 10,
        max: 100,
    },
    {
        name: `$100 to $1000`,
        min: 100,
        max: 1000,
    },
];
export const ratings = [
    {
        name: '4stars & up',
        rating:4
    },
    {
        name: '3stars & up',
        rating:3
    },
    {
        name: '2stars & up',
        rating:2
    },
    {
        name: '1stars & up',
        rating:1
    },
]

export const IMAGE_URL = 'https://api-lomani.ashraftanin.com/uploads/';
export const BASE_URL = 'https://api-lomani.ashraftanin.com';

//export const IMAGE_URL = 'http://localhost:8000/uploads/';
//export const BASE_URL = 'http://localhost:8000';